import $ from "jquery";
import {Bloodhound} from "typeahead.js-jspm";
import typeahead from "typeahead.js-jspm";

typeahead.loadjQueryPlugin();

$(document).ready(function () {

    $.ajaxSetup({cache: false});

    var adherentEngine = new Bloodhound({
        datumTokenizer: function (datum) {
            return Bloodhound.tokenizers.whitespace(datum);
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: Routing.generate('impersonate', {type: 'ADHERENT', query: 'QUERY'}),
            wildcard: 'QUERY'
        }
    });
    adherentEngine.initialize();

    var animateurEngine = new Bloodhound({
        datumTokenizer: function (datum) {
            return Bloodhound.tokenizers.whitespace(datum);
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: Routing.generate('impersonate', {type: 'ANIMATEUR', query: 'QUERY'}),
            wildcard: 'QUERY'
        }
    });
    animateurEngine.initialize();

    var user = document.querySelector('.js-user-role');
    var role_user_current = user ? user.dataset.role : null;

    if (role_user_current == 'ROLE_SIEGE') {

        $('#app-search-impersonate').typeahead({
                hint: false,
                highlight: true,
                minLength: 2,
                template: {}
            }, {
                name: 'animateur',
                display: 'name',
                limit: 20,
                source: animateurEngine,
                templates: {
                    header: ['<h3 class="app-search-type">',
                        'Animateurs',
                        '</h3>'].join('\n'),

                    empty: [
                        '<h3 class="app-search-type">',
                        'Animateurs',
                        '</h3>',
                        '<div class="app-search-empty-message">',
                        'Aucun animateur disponible pour cette recherche',
                        '</div>'
                    ].join('\n'),
                    suggestion:
                        function (data) {
                            return '<div> ' + data.name + '</div>'
                        }
                }
            },
            {
                name: 'adherent',
                display: 'name',
                limit: 20,
                source: adherentEngine,
                templates: {
                    header: ['<h3 class="app-search-type">',
                        'Adhérents',
                        '</h3>'].join('\n'),
                    empty: [
                        '<h3 class="app-search-type">',
                        'Adhérents',
                        '</h3>',
                        '<div class="app-search-empty-message">',
                        'Aucune adhérent disponible pour cette recherche',
                        '</div>'
                    ].join('\n'),
                    suggestion:
                        function (data) {
                            return '<div> ' + data.name + '</div>'

                        }
                }
            }
        )
            .on('typeahead:select', function (e, selected) {
                document.location.href = "/impersonate/redirect/" + selected.id;
            })
            .on('keydown', function (e){
                if(e.keyCode === 13){
                    e.preventDefault();
                    return false;
                }
            })

    }
    if (role_user_current == 'ROLE_ANIMATEUR') {


        $('#app-search-impersonate').typeahead({
                hint: false,
                highlight: true,
                minLength: 2,
                template: {}
            },
            {
                name: 'adherent',
                display: 'name',
                limit: 20,
                source: adherentEngine,
                templates: {
                    header: ['<h3 class="app-search-type">',
                        'Adhérents',
                        '</h3>'].join('\n'),
                    empty: [
                        '<h3 class="app-search-type">',
                        'Adhérents',
                        '</h3>',
                        '<div class="app-search-empty-message">',
                        'Aucune adhérent disponible pour cette recherche',
                        '</div>'
                    ].join('\n'),
                    suggestion:
                        function (data) {
                            return '<div> ' + data.name + '</div>'

                        }
                }
            }
        )
            .on('typeahead:select', function (e, selected) {
                var dateTimeStamp = new Date().getTime();
                document.location.href = "/impersonate/redirect/" + selected.id + "?" + dateTimeStamp;
            })
            .on('keydown', function (e) {
                if (e.keyCode === 13) {
                    e.preventDefault();
                    return false;
                }
            })
    }

    $('.dropdown-menu-right .twitter-typeahead').bind('typeahead:open', function () {
        $(".twitter-typeahead .tt-menu").css("right", "0px");
        $(".twitter-typeahead .tt-menu").css("left", "unset");
    });

});

function reloadImpresonate(url) {
    $.ajax({
        type: "GET",
        data: "url=" + url,
        cache: false,
        headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
        },
        url: "/impersonate/redirect",
        success: function (data) {
            // console.log(data);
        }
    });
}