import $ from "jquery";
import Axios from "axios";

export const PARENT_UPDATE_STATE_AVAILABLE = 'available';
export const PARENT_UPDATE_STATE_REFUSED = 'refused';

export const PERSONAL_DATABASE = 'BASE PERSONNELLE';

export var productDatabaseSelector = (filter, onChange) => (
    <select
        onChange={event => onChange(event.target.value)}
        style={{height: "1.8em", width: "100%"}}
        className={"custom-select"}
        value={filter ? filter.value : "all"}
    >
        <option value="all" style={{fontSize: "1rem"}}>TOUTES LES BASES</option>
        <option value="user" style={{fontSize: "1rem"}}>BASE PERSONNELLE</option>
        <option value="bigmat" style={{fontSize: "1rem"}}>BASE BIGMAT FRANCE</option>
    </select>
);

export const handleProductEdition = (productId, updateDataTableRow) => {
    $("div#productDetails .modal-content #btn_save").one('click', () => {
        $(this).attr('disabled', 'disabled');

        $.post(Routing.generate("product_details_modal"),
            `${$("form#product").serialize()}&productId=${productId}&editMode=1`,
            function (data) {
                if (data.ok) {
                    if (updateDataTableRow) {
                        updateDataTableRow(data.row, productId);
                    }
                    // in this case lets load it up again
                    Axios.get(Routing.generate('product_details_modal'), {
                        params: {
                            productId: productId,
                            editMode: true,
                            timestamp: new Date().getTime() // to invalidate cache
                        }
                    }).then(() => {
                        $("div#productDetails").modal("hide")
                    }).catch((err) => {
                        // console.log("showProduct", err)
                    });
                } else {
                    // in this case lets load it up again with bag error
                    $("div#productDetails .modal-content").html(data);
                    handleProductEdition(productId, updateDataTableRow);
                }
                $("#btn_save").removeAttr('disabled');

            }).fail(function (error) {
            // console.log(error);
        });
    })
}

$('div#productDetails').on('shown.bs.modal', function () {

    const $productImages = $('#product_images');
    const $productColorImages = $('#product_colorVignette');

    let isProductImageDone = false
    if (!isProductImageDone) {
        if ($('.file-preview-frame.product_images').length === 0) {
            // krajee gaves no way to render anything post load
            let i = 0;
            const timer = setInterval(function () {
                if ($('.file-preview-frame.product_images').length !== 0) {
                    clearInterval(timer);
                    loadImageTagAndButton()
                    isProductImageDone = true;
                }
                if (++i > 5) {
                    clearInterval(timer);
                    isProductImageDone = true;
                }
            })
        } else {
            loadImageTagAndButton()
            isProductImageDone = true;
        }
    }

    let isProductColorImageDone = false;
    if (!isProductColorImageDone) {
        if ($('.file-preview-frame.product_colorVignette').length === 0) {
            // krajee gaves no way to render anything post load
            let i = 0;
            const timer = setInterval(function () {
                if ($('.file-preview-frame.product_colorVignette').length !== 0) {
                    clearInterval(timer);
                    loadImageTextInputAndActiveButton()
                    isProductColorImageDone = true;
                }
                if (++i > 5) {
                    clearInterval(timer);
                    isProductColorImageDone = true;
                }
            })
        } else {
            loadImageTextInputAndActiveButton()
            isProductColorImageDone = true;
        }
    }


    $productImages.on('filesorted', function (event, params) {

        $.post(Routing.generate("sort_uploaded_files"),
            {
                assetsType: "images",
                orderedAssets: params.stack
            },
            function (data) {
                loadImageTagAndButton();
            }).fail(function (error) {
        });
    });

    $productColorImages.on('filesorted', function (event, params) {

        $.post(Routing.generate("sort_uploaded_files"),
            {
                assetsType: "colorVignette",
                orderedAssets: params.stack
            },
            function (data) {
                loadImageTextInputAndActiveButton();
            }).fail(function (error) {
        });
    });

    $productImages.on('fileuploaded', function (event, data, previewId, index) {
        var $uploaded_data = $('#' + this.id + '_uploaded_data');
        var uploadedAssetId = data.jqXHR.responseJSON.initialPreviewConfig[0]['key'];
        var currentVal = $uploaded_data.val();
        $uploaded_data.val(currentVal + (currentVal !== '' ? ';' : '') + uploadedAssetId);
    });

    $productColorImages.on('fileuploaded', function (event, data, previewId, index) {
        var $uploaded_data = $('#' + this.id + '_uploaded_data');
        var uploadedAssetId = data.jqXHR.responseJSON.initialPreviewConfig[0]['key'];
        var currentVal = $uploaded_data.val();
        $uploaded_data.val(currentVal + (currentVal !== '' ? ';' : '') + uploadedAssetId);
    });


    $productImages.on('filebatchuploadcomplete', function (event) {
        loadImageTagAndButton();
    });

    $productColorImages.on('filebatchuploadcomplete', function (event) {
        loadImageTextInputAndActiveButton();
    });

    $productImages.on('filedeleted', function (event, key, jqXHR, data) {
        var $uploaded_data = $('#' + this.id + '_uploaded_data');
        var currentVal = $uploaded_data.val();
        var regexSemiColonBefore = new RegExp(";" + key, "g");
        var regexSemiColonAfter = new RegExp(key + ";", "g");
        $uploaded_data.val(currentVal.replace((regexSemiColonBefore.exec(currentVal)) ? ";" + key : (regexSemiColonAfter.exec(currentVal)) ? key + ";" : '', ''));

        loadImageTagAndButton();
    });

    $productColorImages.on('filedeleted', function (event, key, jqXHR, data) {
        var $uploaded_data = $('#' + this.id + '_uploaded_data');
        var currentVal = $uploaded_data.val();
        var regexSemiColonBefore = new RegExp(";" + key, "g");
        var regexSemiColonAfter = new RegExp(key + ";", "g");
        $uploaded_data.val(currentVal.replace((regexSemiColonBefore.exec(currentVal)) ? ";" + key : (regexSemiColonAfter.exec(currentVal)) ? key + ";" : '', ''));

        loadImageTextInputAndActiveButton();
    });
})


export function loadImageTagAndButton() {

    $('.file-preview-frame.product_images').each(function (index) {
        let that = $(this);
        let productId = $('#productDetails .modal-header').attr('data-productId');
        let assetId = $(this).find('.kv-file-remove').attr('data-key');
        $.get(Routing.generate("get_product_image_detail", {productId, assetId}),
            {},
            function (response) {
                if (typeof response.buttons !== 'undefined' && response.buttons !== null) {
                    if (index !== 0) {
                        // first image can not be both principal and focus
                        addImageButton(that, response.buttons);
                    } else {
                        removeImageButton(that);
                    }
                }
                if (typeof response.tag !== 'undefined' && response.tag !== null && typeof response.tag.text !== 'undefined' && typeof response.tag.type !== 'undefined') {
                    removeImageTag(that);
                    addImageTag(that, response.tag.text, response.tag.type);
                    toggleActivateButton(that, true);
                } else {
                    removeImageTag(that);
                    toggleActivateButton(that, false);
                }
            }).fail(function (error) {
        });
    });
}


export function loadImageTextInputAndActiveButton() {

    $('.file-preview-frame.product_colorVignette').each(function (index) {
        let that = $(this);
        let productId = $('#productDetails .modal-header').attr('data-productId');
        let assetId = $(this).find('.kv-file-remove').attr('data-key');
        $.get(Routing.generate("get_product_image_coloris_detail", {productId, assetId}),
            {},
            function (response) {

                if (typeof response.buttons !== 'undefined' && response.buttons !== null) {
                    addImageButton(that, response.buttons);
                    addTitleEditable(that);
                }
                if (typeof response.tag !== 'undefined' && response.tag !== null && typeof response.tag.text !== 'undefined' && typeof response.tag.type !== 'undefined') {
                    removeImageTag(that);
                    addImageTag(that, response.tag.text, response.tag.type);
                    toggleActivateButton(that, true);
                } else {
                    removeImageTag(that);
                    toggleActivateButton(that, false);
                }
            }).fail(function (error) {
            // console.error(error);
        });
    });
}

function addTitleEditable($preview) {
    let $previewTitle = $preview.find('.file-caption-info');
    let content = $previewTitle.text();
    if ($previewTitle.length > 0) {
        if ($previewTitle.find('.custom-imageTitle-button').length === 0) {
            let buttonHtml = '<button type="button" ' +
                'class="custom-imageTitle-button changeTitle btn btn-sm btn-kv btn-default btn-outline-secondary" ' +
                'data-value="' + content + '" ' +
                'title="edit">' +
                '<i class="glyphicon glyphicon-pencil"></i>' +
                '</button>';
            $previewTitle.append(buttonHtml);
        }
    }
}

function toggleActivateButton($preview, bool) {
    if ($preview.find('.custom-image-button').length > 0) {
        if (!$preview.find('.custom-image-button').hasClass('active') && bool) {
            $preview.find('.custom-image-button').addClass('active');
        }
        if ($preview.find('.custom-image-button').hasClass('active') && !bool) {
            $preview.find('.custom-image-button').removeClass('active');
        }
    }
}

function removeImageTag($preview) {

    if ($preview.find('.custom-product-image-badge').length > 0) {
        $preview.find('.custom-product-image-badge').remove();
    }
}

function removeImageButton($preview) {

    if ($preview.find('.custom-image-button').length > 0) {
        $preview.find('.custom-image-button').remove();
    }
}

function addImageTag($preview, text, type) {

    if ($preview.find('.custom-product-image-badge').length === 0) {
        $preview.find('.file-footer-caption').append('<span class="custom-product-image-badge badge badge-' + type + '">' + text + '</span>')
    }
}

function addImageButton($preview, buttons) {

    if (buttons.length === 0 && $preview.find('.custom-image-button').length > 0) {
        $preview.find('.custom-image-button').remove();
    }

    buttons.forEach(function (button) {

        if (typeof button.fieldType !== 'undefined' && typeof button.buttonName !== 'undefined' && $preview.find('.custom-image-button').length === 0) {
            let buttonHtml = '';
            switch (button.fieldType) {
                case 'select':
                    buttonHtml = '<button type="button" ' +
                        'class="custom-image-button btn btn-sm btn-kv btn-default btn-outline-secondary" ' +
                        'title="' + button.options[1].value + '" ' +
                        'data-type="' + button.buttonName + '" ' +
                        'data-value="' + button.options[1].value + '">' +
                        '<i class="' + button.buttonIcon + '"></i>' +
                        '</button>';
                    break;
                case 'checkbox':
                    buttonHtml = '<button type="button" ' +
                        'class="custom-image-button btn btn-sm btn-kv btn-default btn-outline-secondary" ' +
                        'title="' + button.buttonTitle + '" ' +
                        'data-type="' + button.buttonName + '" ' +
                        'data-value="' + button.buttonTitle + '">' +
                        '<i class="' + button.buttonIcon + '"></i>' +
                        '</button>';
                    break;
            }
            $preview.find('.file-footer-buttons').append(buttonHtml);
        }

    });
}

export const showUpdateDiffModal = (productId) => {
    Axios.get(Routing.generate('product_updates_diff', {id: productId}), {
        params: {
            timestamp: new Date().getTime() // to invalidate cache
        }
    }).then(data => {
        $("div#productUpdatesDiff .modal-body .content").html(data.data)
        $("div#productUpdatesDiff").modal("show")
    }).catch((err) => {
        // console.log("showProduct", err)
    });
}