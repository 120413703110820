import $ from "jquery";
import {
    loadImageTagAndButton,
    loadImageTextInputAndActiveButton
} from "./datatable/ProductDataTable/ProductDataTableComponent/datatable.product.common";

$(document).ready(function () {

    $(document).on('click', '.file-preview-frame.product_images .custom-image-button', function () {
        const productId = $('#productDetails .modal-header').attr('data-productId');
        const assetId = $(this).parent().find('.kv-file-remove').attr('data-key');
        const value = $(this).attr('data-value');
        $.post(Routing.generate("update_product_image_detail", {productId, assetId, value}), function (response) {
            if (response.ok) {
                loadImageTagAndButton();
            }
        });
    })

    $(document).on('click', '.file-preview-frame.product_colorVignette .custom-image-button', function () {
        const productId = $('#productDetails .modal-header').attr('data-productId');
        const assetId = $(this).parent().find('.kv-file-remove').attr('data-key');
        const value = $(this).attr('data-value');
        $.post(Routing.generate("update_product_color_image_detail", {
            productId,
            assetId,
            value,
            type: 'active'
        }), function (response) {
            if (response.ok) {
                loadImageTextInputAndActiveButton();
            } else {
                alert(response.message);
            }
        });
    })

    $(document).on('click', '.file-preview-frame.product_colorVignette .custom-imageTitle-button.changeTitle', function () {

        const value = $(this).attr('data-value');

        let html = '<input type="text" name="imageTitle" placeholder="' + value + '"/>';
        html += '<button type="button" ' +
            'class="custom-imageTitle-button saveTitle btn btn-sm btn-kv btn-default btn-outline-secondary" ' +
            'title="save">' +
            '<i class="glyphicon glyphicon-save"></i>' +
            '</button>';
        html += '<button type="button" ' +
            'class="custom-imageTitle-button cancelChangeTitle btn btn-sm btn-kv btn-default btn-outline-secondary" ' +
            'title="cancel" ' +
            'data-oldValue="' + value + '" >' +
            '<i class="glyphicon glyphicon-remove"></i>' +
            '</button>';

        $(this).parent().html(html);
    })

    $(document).on('click', '.file-preview-frame.product_colorVignette .custom-imageTitle-button.cancelChangeTitle', function () {

        const value = $(this).attr('data-oldvalue');

        let html = value;
        html += '<button type="button" ' +
            'class="custom-imageTitle-button changeTitle btn btn-sm btn-kv btn-default btn-outline-secondary" ' +
            'data-value="' + value + '" ' +
            'title="edit">' +
            '<i class="glyphicon glyphicon-pencil"></i>' +
            '</button>';

        $(this).parent().html(html);
    })

    $(document).on('click', '.file-preview-frame.product_colorVignette .custom-imageTitle-button.saveTitle', function () {

        const $that = $(this);
        const value = $(this).parent().find('input[name=imageTitle]').val();
        const productId = $('#productDetails .modal-header').attr('data-productId');
        const assetId = $(this).closest('.file-preview-frame').find('.kv-file-remove').attr('data-key');
        $.post(Routing.generate("update_product_color_image_detail", {
            productId,
            assetId,
            value,
            type: 'imageTitle'
        }), function (response) {
            if (response.ok) {
                let html = value;
                html += '<button type="button" ' +
                    'class="custom-imageTitle-button changeTitle btn btn-sm btn-kv btn-default btn-outline-secondary" ' +
                    'data-value="' + value + '" ' +
                    'title="edit">' +
                    '<i class="glyphicon glyphicon-pencil"></i>' +
                    '</button>';

                $that.parent().html(html);
            } else {
                alert(response.message);
            }
        });

        let html = value;
        html += '<button type="button" ' +
            'class="custom-imageTitle-button changeTitle btn btn-sm btn-kv btn-default btn-outline-secondary" ' +
            'data-value="' + value + '" ' +
            'title="edit">' +
            '<i class="glyphicon glyphicon-pencil"></i>' +
            '</button>';

        $(this).parent().html(html);
    })

    $('body').on('filebatchselected', '.file-input input:file', function (event, files) {
        var $this = $(this);

        function upload($this) {
            $this.parent('div').prev('.fileinput-upload').hide();
            $this.fileinput('upload');
        }

        if (files.length > 0) {
            setTimeout(upload.bind(null, $this), 2000);
        }
    });
});