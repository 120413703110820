import $ from "jquery"
import Axios from "axios"

$('.support-preview').click(function (event) {
    event.preventDefault();
    var supportId = $(this).data('support-id');

    Axios.get(Routing.generate('support_show_carousel_modal', {id: supportId}))
        .then(res => {
            $("div#modal_preview_carousel_support .modal-body").html(res.data)
            $("div#modal_preview_carousel_support").modal("show")
        }).catch((err) => {
        //console.log("showCarousel", err)
    })
});