import $ from "jquery";
import {Bloodhound} from "typeahead.js-jspm";
import typeahead from "typeahead.js-jspm";

typeahead.loadjQueryPlugin();

$(document).ready(function () {

    var assetEngine = new Bloodhound({
        datumTokenizer: function (datum) {
            return Bloodhound.tokenizers.whitespace(datum);
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: Routing.generate('search', {type: 'ASSET', query: 'QUERY'}),
            wildcard: 'QUERY'
        }
    });

    var supportEngine = new Bloodhound({
        datumTokenizer: function (datum) {
            return Bloodhound.tokenizers.whitespace(datum);
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: Routing.generate('search', {type: 'SUPPORT', query: 'QUERY'}),
            wildcard: 'QUERY'
        }
    });

    assetEngine.initialize();
    supportEngine.initialize();

    $('#app-search')
        .typeahead({
                hint: false,
                highlight: true,
                minLength: 3,
                template: {}
            }, {
                name: 'assets',
                display: 'name',
                limit: 20,
                source: assetEngine,
                templates: {
                    header: ['<h3 class="app-search-type">',
                        'Images',
                        '</h3>'].join('\n'),

                    empty: [
                        '<h3 class="app-search-type">',
                        'Images',
                        '</h3>',
                        '<div class="app-search-empty-message">',
                        'Aucune Image disponible pour cette recherche',
                        '</div>'
                    ].join('\n'),
                    suggestion:
                        function (data) {
                            data.tag = data.tag ? '<img src="/bundles/pimcoreadmin/img/flat-color-icons/label.svg" height="25px">' : '';
                            return '<div><span class="search-image"><img class="image" src="' + data.image + '"/></span><span class="search-text"> ' + data.name + ' ' + data.tag + '</span></div>'
                        }
                }
            },
            {
                name: 'support',
                display: 'name',
                limit: 20,
                source: supportEngine,
                templates: {
                    header: ['<h3 class="app-search-type">',
                        'Supports / Mes documents',
                        '</h3>'].join('\n'),
                    empty: [
                        '<h3 class="app-search-type">',
                        'Supports',
                        '</h3>',
                        '<div class="app-search-empty-message">',
                        'Aucune support disponible pour cette recherche',
                        '</div>'
                    ].join('\n'),
                    suggestion:
                        function (data) {
                            data.tag = data.tag ? '<img src="/bundles/pimcoreadmin/img/flat-color-icons/label.svg" height="25px">' : '';
                            return '<div><img class="image" src="' + data.image + '"/> ' + data.name + ' ' + data.tag + '</div>'

                        }
                }
            }
        )
        .on('typeahead:select', function (e, selected) {
            document.location.href = selected.url;
        })
        .on('keydown', function (e) {
            if (e.keyCode === 13) {
                e.preventDefault();
                return false;
            }
        })

});